$(document).ready(function() {
	
	/* input mask 
    ====================================*/
	$('.for_mask').inputmask("+7 (999) 999 99 99");
	
//	$('.btn-red').easyAudioEffects({
//		mp3 : "audio/mysound.mp3",
//		eventType : "hover"
//	});
//	
//    if ($('.js-slider-1').length > 0) {
//        $('.js-slider-1').slick({
//            arrows: true,
////            autoplay: true,
//            autoplaySpeed: 2000,
//            dots: false,
//            infinite: true,
//            slidesToShow: 4,
//            slidesToScroll: 4,
//            speed: 1500,
//			responsive: [
//				{
//					breakpoint: 750,
//					settings: {
//						slidesToShow: 3,
//						slidesToScroll: 3,
//					}
//				},
//				{
//					breakpoint: 545,
//					settings: {
//						slidesToShow: 2,
//						slidesToScroll: 2,
//					}
//				},
//				{
//					breakpoint: 410,
//					settings: {
//						slidesToShow: 1,
//						slidesToScroll: 1,
//					}
//				}
//			]
//        });
//    }
	
//	if ($('.js-slider-1').length > 0) {
//		$('.js-slider-1').slick({
//			arrows: false,
////			autoplay: true,
//			autoplaySpeed: 2000,
//			dots: true,
//			infinite: true,
//			slidesToShow: 1,
//			slidesToScroll: 1,
//			speed: 1500
//		});
//	}
//	
//	if ($('.js-slider-2').length > 0) {
//		$('.js-slider-2').slick({
//			arrows: false,
//			//			autoplay: true,
//			autoplaySpeed: 2000,
//			dots: true,
//			infinite: true,
//			slidesToShow: 1,
//			slidesToScroll: 1,
//			speed: 1500
//		});
//	}
	
	// accordeon
//	var answer = $('.js-answer'),
//		question = $('.js-question');
//	
//	$(answer).not(':first').hide();
//	$(question).first().find('.accordeon__arrow').addClass('accordeon__arrow--active');
//	
//	$(question).on('click', function() {
//		$(this).next('.js-answer').slideToggle();
//		$(this).find('.accordeon__arrow').toggleClass('accordeon__arrow--active');
//		$(this).toggleClass('accordeon__title--active');
//	});
    
//	if ($('.js-slider-3').length > 0) {
//		$('.js-slider-3').slick({
//			//			autoplay: true,
//			autoplaySpeed: 2000,
//			dots: false,
//			infinite: true,
//			slidesToShow: 2,
//			slidesToScroll: 2,
//			speed: 1500,
//			responsive: [
//				{
//					breakpoint: 965,
//					settings: {
//						slidesToShow: 1,
//						slidesToScroll: 1,
//					}
//				}
//			]
//		});
//	}
    
	// mobile_menu
	$(".toggle-mnu").click(function() {
		$(this).toggleClass("on");
		return false;
	});
	
	var pull         = $('#pull'),
		menu         = $('.menu'),
		menuHeight    = menu.height();
	
	$(pull).on('click', function() {
		menu.toggleClass('menu--active');
		return false;
	});
	
	function closeMnuOut() {
		$(document).mouseup(function (e) {
			if($('.toggle-mnu').is(':visible')) {
				var container = $(".menu, #pull");
				if (!container.is(e.target) // if the target of the click isn't the container...
					&& container.has(e.target).length === 0) // ... nor a descendant of the container
				{
					menu.removeClass('menu--active');
					$('#pull').removeClass('on');
				}
			}
		});
	}
	
	closeMnuOut();
	
	$(window).resize(function(){
		closeMnuOut();
		var w = $(window).width();
		if(w > 320 && menu.is(':hidden')) {
			menu.removeClass('menu--active');
			pull.removeClass('on');
		}
	});
	
	/* Перемотка
    ====================================*/   
//	$("#js-nav a").on("click", function(e) {
//
//		e.preventDefault();
//
//		var currentBlock = $(this).attr("href"),
//			currentBlockOffset = $(currentBlock).offset().top;
//
//		$("html, body").animate({
//			scrollTop: currentBlockOffset - 20
//		}, 500);
//		menu.removeClass('menu--active');
//		pull.removeClass('on');
//	});
	
	
	/* read more
    ====================================*/   
//	var readMore__text = $('.js-readMore__text'),
//		readMore = $('.js-readMore');
//	
//	$(readMore__text).hide();
//	$(readMore).on('click', function(e) {
//		e.preventDefault();
//		$(readMore__text).slideToggle(function() {
//			if ($(this).is(':hidden')) {
//				$(readMore).text('Читать подробнее');
//			}
//			if ($(this).is('visible')) {
//				$(readMore).text('Скрыть');
//			}
//		});
//	});
    
    /* Modals
    ====================================*/    
    $(document).keydown(function(e) {
        if (e.keyCode == 27) {
            $(".js-popup, #js-overlay").fadeOut(300);
        }
    });

    $('.js-btn').on('click', function(){
        var id = $(this).attr('data-link');
        $('#' + id).fadeIn(500);
        $('#js-overlay').fadeIn(500);
        return false;

    });

    $(".popup__close, #js-overlay").on("click", function() {
        $(".js-popup, #js-overlay").fadeOut(300);
    });
});




